import { ARMY_DODICS, MODAL_STYLE_FIT_CONTENT, STR_PROJECT_MANAGER_EMAIL, STR_PROJECT_MANAGER_INFO } from "../constants";
import Modal from 'react-modal';
import { ReactElement, useState, useEffect } from "react";
import { ALL_CHAPTERS } from "../constants"
import { ALL_CATEGORIES } from "../constants";
import { ICategory, IChapter, IStrategy } from "../interfaces"
import { Button, IconButton } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select/creatable'
import CompressIcon from '@mui/icons-material/Compress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Iterator from "./iterator2";
import { components } from "react-select";

function AmmoOP(): ReactElement {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [is10Added, set10Added] = useState<boolean>(false);
    const [isEventsMulti, setIsEventsMulti] = useState<boolean>(false);
    const [chapterSelected, setChapterSelected] = useState<string>();
    const [pageSubtitle, setPageSubtitle] = useState<string>();
    const [weaponTags, setWeaponTags] = useState<string[]>([]);
    const [eventTags, setEventTags] = useState<string[]>([]);
    const [weaponValue, setWeaponValue] = useState<Option | null>(null);
    const [eventsValue, setEventsValue] = useState<Option | null>(null);
    const [catValue, setCatValue] = useState<Option | null>(null);
    const [eveValue, setEveValue] = useState<readonly Option[]>([]);
    
    interface Option {
        readonly label: string;
        readonly value: string;
    }

    function createWeaponTagList(): string[] {
        const allWeaponTags: string[] = [];
        for (let i = 0; i < ALL_CATEGORIES.length; i++){
            for ( let k = 0; k < ALL_CATEGORIES[i].chapters.length; k++){
                if (ALL_CATEGORIES[i].chapters[k] === chapterSelected){
                    for (let x = 0; x < ALL_CATEGORIES[i].tags.length; x++){
                        if (ALL_CATEGORIES[i].tags[x] === "Break") break;
                        else if (ALL_CATEGORIES[i].tags[x] === "EventType") break;
                        else if (ALL_CATEGORIES[i].tags[x] !== "Weapon" && !allWeaponTags.includes(ALL_CATEGORIES[i].tags[x]))
                            allWeaponTags.push(ALL_CATEGORIES[i].tags[x]);
                    }
                    break;
                }
            }
        }
        return(allWeaponTags);
    }

    function createEventTagList(): string[] {
        const allEventTags: string[] = [];
        setEventsValue(null);
        if(!weaponValue)
        for ( let i = 0; i < ALL_CATEGORIES.length; i++){
            for ( let k = 0; k < ALL_CATEGORIES[i].chapters.length; k++){
                if (ALL_CATEGORIES[i].chapters[k] === chapterSelected){
                    for (let x = 0; x < ALL_CATEGORIES[i].tags.length; x++){
                        if (ALL_CATEGORIES[i].tags[x] === "EventType"){
                            for (let y = x; y < ALL_CATEGORIES[i].tags.length; y++){
                                if(ALL_CATEGORIES[i].tags[y] !== "Break" && ALL_CATEGORIES[i].tags[y] !== "EventType" && !allEventTags.includes(ALL_CATEGORIES[i].tags[y]))
                                    allEventTags.push(ALL_CATEGORIES[i].tags[y]);
                            }
                            break;
                        }
                    }
                    break;
                }
            }
        }
        else
        for ( let i = 0; i < ALL_CATEGORIES.length; i++){
            for ( let k = 0; k < ALL_CATEGORIES[i].chapters.length; k++){
                if (ALL_CATEGORIES[i].chapters[k] === chapterSelected){
                    for ( let j = 0; j < catOptions.length; j++)
                        if (ALL_CATEGORIES[i].tags.includes(weaponValue.label))
                            for (let x = 0; x < ALL_CATEGORIES[i].tags.length; x++){
                                if (ALL_CATEGORIES[i].tags[x] === "EventType"){
                                    for (let y = x; y < ALL_CATEGORIES[i].tags.length; y++){
                                        if(ALL_CATEGORIES[i].tags[y] !== "Break" && ALL_CATEGORIES[i].tags[y] !== "EventType" && !allEventTags.includes(ALL_CATEGORIES[i].tags[y]))
                                            allEventTags.push(ALL_CATEGORIES[i].tags[y]);
                                    }
                                    break;
                                }
                            }
                    break;
                }
            }
        }
        return(allEventTags);
    }

    function createWeaponsList() {
        const weaponOptions = [];
        for (let i = 0; i < weaponTags.length; i++){
            weaponOptions.push({value: weaponTags[i], label: weaponTags[i]});
        }
        return weaponOptions;
    }

    function createEventTagsList() {
        const eventOptions = [];
        for (let i = 0; i < eventTags.length; i++){
            eventOptions.push({value: eventTags[i], label: eventTags[i]});
        }
        return eventOptions;
    }
    
    function createCategoriesList() {
        const categoryOptions = [];
        for (let i = 0; i < ALL_CATEGORIES.length; i++){
            for (let x = 0; x < ALL_CATEGORIES[i].chapters.length; x++)
                if (ALL_CATEGORIES[i].chapters[x] === chapterSelected)
                    if (weaponValue || eventsValue){
                        for (let y = 0; y < ALL_CATEGORIES[i].tags.length; y++){
                            if (weaponValue && !eventsValue){
                                if (ALL_CATEGORIES[i].tags.includes(JSON.stringify(weaponValue.value).replace(/['"]+/g, ''))){
                                    categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                                    break;
                                }
                            }
                            if (eventsValue && !weaponValue){
                                if (ALL_CATEGORIES[i].tags.includes(JSON.stringify(eventsValue.value).replace(/['"]+/g, ''))){
                                    categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                                    break;
                                }
                            }
                            if (eventsValue && weaponValue){
                                if (ALL_CATEGORIES[i].tags.includes(JSON.stringify(weaponValue.value).replace(/['"]+/g, '')) &&
                                    ALL_CATEGORIES[i].tags.includes(JSON.stringify(eventsValue.value).replace(/['"]+/g, ''))){
                                    categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                                    break;
                                }
                            }
                        }
                    }
                    else {
                        categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                    }
        }
        return categoryOptions;
    }

    function createEventList() {
        let category: ICategory = 
        {
            "name": "Dummy Category",
            "chapters":["DEBUG"],
            "events": [
                { "name": "You shouldn't see this", "strategies": [], "isSelected": false },
            ],
            "tags": ["DEBUG"]
        };
        for(let i = 0; i < ALL_CATEGORIES.length; i++)
            for(let x = 0; x < ALL_CATEGORIES[i].chapters.length; x++)
                if (catValue?.label === ALL_CATEGORIES[i].name && ALL_CATEGORIES[i].chapters[x] === chapterSelected){ 
                    category = ALL_CATEGORIES[i];
        }
        const eventOptions = [];
        for (let i = 0; i < category.events.length; i++) eventOptions.push({value: category.events[i].name, label: category.events[i].name});
        return eventOptions;
    }


    const [catOptions, setCatOptions] = useState(createCategoriesList());
    const [eveOptions, setEveOptions] = useState(createEventList());
    const [weaponOptions, setWeaponOptions] = useState(createWeaponsList());
    const [eventsOptions, setEventsOptions] = useState(createEventTagsList());
    const [weaponCount, setweaponCount] = useState<number>(1);
    const [iterationCount, setIterationCount] = useState<number>(1);


    useEffect(() => {
        if (chapterSelected){
            for (let i = 0; i < ALL_CHAPTERS.length; i++)
                if (chapterSelected === ALL_CHAPTERS[i].name)
                    setPageSubtitle(`${ALL_CHAPTERS[i].tags[0]} - ${chapterSelected}`)
            if (chapterSelected) setCatOptions(createCategoriesList());
            setWeaponTags(createWeaponTagList())
            setEventTags(createEventTagList())
            setWeaponValue(null)
            setCatValue(null)
            setEveValue([])
            setEventsValue(null)
            setweaponCount(1)
            setIterationCount(1)
        }
    }, [chapterSelected])
    useEffect(() => {
        const doNothing = () => { return }
        doNothing()
    }, [pageSubtitle])
    useEffect(() => {
        setEveOptions(createEventList());
    }, [catValue])
    useEffect(() => {
        setWeaponOptions(createWeaponsList());
    }, [weaponTags])
    useEffect(() => {
        setEventsOptions(createEventTagsList());
    }, [eventTags])
    useEffect(() => {
        setEventTags(createEventTagList())
    }, [weaponValue])
    useEffect(() => {
        setCatOptions(createCategoriesList());
    }, [eventsValue, weaponValue])
    useEffect(() => {
        if (eveValue.length && chapterSelected){
            calculate(is10Added)
        }
    }, [weaponCount, iterationCount, eveValue])
    useEffect(() => {
        set10Added(false)
        if (eveValue.length > 1)
            setIsEventsMulti(true)
        else
            setIsEventsMulti(false)
    }, [eveValue])
    useEffect(() => {
        if (weaponOptions.length === 1)
            setWeaponValue(weaponOptions[0])
    }, [weaponOptions])
    useEffect(() => {
        if (eventsOptions.length === 1)
            setEventsValue(eventsOptions[0])
    }, [eventsOptions])
    useEffect(() => {
        if (catOptions.length === 1)
            setCatValue(catOptions[0]);
    }, [catOptions])
    useEffect(() => {
        if (eveOptions.length === 1 && eveOptions[0].value != "You shouldn't see this")
            setEveValue([eveOptions[0]])
        else
            setEveValue([]);
    }, [eveOptions])
    
    const calculate = (is10: boolean) => { 
        const Dodics: { [charRep : string]: IStrategy[] } = {};
        const calculationsExportable: IStrategy[] = [];
        const elements: ReactElement[] = [];
        for (let i = 0; i < ALL_CATEGORIES.length; i++)
            if (chapterSelected) if (ALL_CATEGORIES[i].chapters.includes(chapterSelected) && ALL_CATEGORIES[i].name === catValue?.value)
                for (let x = 0; x < ALL_CATEGORIES[i].events.length; x++)
                    for (let z = 0; z < eveValue.length; z++)
                        if (eveValue[z].label === ALL_CATEGORIES[i].events[x].name)
                            for (let y = 0; y < ALL_CATEGORIES[i].events[x].strategies.length; y++){
                                if (Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep] === undefined){
                                    Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep] = [];
                                    Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep].push({charRep:ALL_CATEGORIES[i].events[x].strategies[y].charRep, count: 0 })
                                }
                                Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep].push(ALL_CATEGORIES[i].events[x].strategies[y])
                            }
        if(is10)
            for (const key in Dodics){
                for (let i = 0; i < ARMY_DODICS.length; i++)
                    if (Dodics[key][0].charRep === ARMY_DODICS[i].charRep){
                        let sum = 0;
                        for (let x = 1; x < Dodics[key].length; x++)
                            sum += Dodics[key][x].count;
                        if (sum || Dodics[key][0].count != 0){
                        calculationsExportable.push({charRep: Dodics[key][0].charRep, count: (Math.ceil((sum * weaponCount * iterationCount) + Dodics[key][0].count))})
                        elements.push(
                            <TableRow>
                                <TableCell>{((Math.ceil(sum * weaponCount * iterationCount + sum*weaponCount*iterationCount*.1)))}</TableCell>
                                <TableCell>{ARMY_DODICS[i].ammoName}</TableCell>
                                <TableCell>{ARMY_DODICS[i].charRep}</TableCell>
                            </TableRow>
                        )
                        }
                    }
            }
        else
            for (const key in Dodics){
                for (let i = 0; i < ARMY_DODICS.length; i++)
                    if (Dodics[key][0].charRep === ARMY_DODICS[i].charRep){
                        let sum = 0;
                        for (let x = 1; x < Dodics[key].length; x++)
                            sum += Dodics[key][x].count;
                        if (sum || Dodics[key][0].count != 0){
                        calculationsExportable.push({charRep: Dodics[key][0].charRep, count: (Math.ceil((sum * weaponCount * iterationCount) + Dodics[key][0].count))})
                        elements.push(
                            <TableRow>
                                <TableCell>{(Math.ceil(sum * weaponCount * iterationCount))}</TableCell>
                                <TableCell>{ARMY_DODICS[i].ammoName}</TableCell>
                                <TableCell>{ARMY_DODICS[i].charRep}</TableCell>
                            </TableRow>
                        )
                        }
                    }
            }
        return(elements)
    }
    
    function Header() {
        return(
            <header>
                {!chapterSelected &&
                <div className="header">
                    <img className= "ammoicon"src="ammoop.png" alt="ammoop" />
                    <Button variant="contained" sx={{overflow: "initial", width: "fit-content", position: "absolute", top: "22px", right: "230px", height: "fit-content",
                    float: "right", borderRadius: "5px", backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}}
                    href="/edit">+ UPDATE STRAC</Button>
                    <Button variant="contained" sx={{overflow: "initial", width: "fit-content", position: "absolute", top: "22px", right: "20px", height: "fit-content",
                    float: "right", borderRadius: "5px", backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}}
                    href="/edit">+ NEW TRAINING</Button>
                </div>
                }
                {chapterSelected &&
                <div className="header">
                    <div>
                        <Button variant="contained" sx={{float: "left", marginLeft: "10px", position: "absolute", height: "fit-content", width: "fit-content", top: "22px",
                            backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}} onClick={() => {setChapterSelected(undefined)}}
                            startIcon={<ArrowBackIcon />}>Change
                        </Button>
                        <p style={{color: "white", position: "absolute", left: "150px", top: "12px"}}>{`${pageSubtitle}`}</p>
                    </div>
                    <img className= "ammoicon"src="ammoop.png" alt="ammoop" />
                    <Button variant="contained" sx={{overflow: "initial", width: "fit-content", position: "absolute", top: "22px", right: "230px", height: "fit-content",
                    float: "right", borderRadius: "5px", backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}}
                    href="/edit">+ UPDATE STRAC</Button>
                    <Button variant="contained" sx={{overflow: "initial", width: "fit-content", position: "absolute", top: "22px", right: "20px", height: "fit-content",
                    float: "right", borderRadius: "5px", backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}}
                    href="/edit">+ NEW TRAINING</Button>
                </div>
                }
            </header>
        )
    }
    
    function MainContent() {
        function getCombatArms() {
            const sorted_chapters = []
            for (let i = 0; i < ALL_CHAPTERS.length; i++)
                if (ALL_CHAPTERS[i].tags.includes("Combat Arms"))
                    sorted_chapters.push(ALL_CHAPTERS[i])
            return(sorted_chapters)
        }
        
        function getCSS() {
            const sorted_chapters = []
            for (let i = 0; i < ALL_CHAPTERS.length; i++)
                if (ALL_CHAPTERS[i].tags.includes("Combat (Service) Support"))
                    sorted_chapters.push(ALL_CHAPTERS[i])
            return(sorted_chapters)
        }
    
        function getMisc() {
            const sorted_chapters = []
            for (let i = 0; i < ALL_CHAPTERS.length; i++)
                if (ALL_CHAPTERS[i].tags.includes("Special Use Case"))
                    sorted_chapters.push(ALL_CHAPTERS[i])
            return(sorted_chapters)
        }

        function givePlaceholder(bool: boolean) {
            if(bool)
                return "Multiple Selections"
            else
                return "Select Training Exercises..."
        }
    
        const combatChapters = getCombatArms()
        const cssChapters = getCSS()
        const miscChapters=getMisc()
        const Option = (props: any) => { return ( <div> <components.Option {...props}> <input type="checkbox" checked={props.isSelected} onChange={() => null} /> 
        <label>{props.value}</label> </components.Option> </div> ); }; 
    
        function buttonCreator(sorted_chapters: IChapter[]) {
            const elements: ReactElement[] = [];
                for (let i = 0; i < sorted_chapters.length; i++){
                    if (sorted_chapters[i].image)
                        elements.push(
                            <div className="btndiv">
                                <IconButton sx={{    
                                    display: "flex", width: "78px", height: "78px", "&:hover": { width: "87px", height: "87px" }, flexDirection: "column", alignItems: "center", gap: "8px",
                                    borderRadius: "8px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                                    marginLeft: "auto", marginRight: "auto", backgroundImage: `url(${sorted_chapters[i].image})`, backgroundSize: 'contain'
                                    }}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                                </IconButton>
                                <div className="branchname" onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                            </div>
                        );
                    else
                        elements.push(
                            <div className="btndiv">
                                <IconButton sx={{    
                                    display: "flex", width: "78px", height: "78px", "&:hover": { width: "87px", height: "87px" }, flexDirection: "column", alignItems: "center", gap: "8px",
                                    borderRadius: "8px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                                    marginLeft: "auto", marginRight: "auto"}}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                                    <CompressIcon/>
                                </IconButton>
                                <div className="branchname" onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                            </div>
                        );
                }
            return(elements)
        }
    
        return(
            <div className="maincontent">
                {!chapterSelected &&
                <div className="branchcontainer">
                    <div className="chapselecttitle">Select Branch</div>
                    <img src="underline.png" style={{width: "15vw"}}/>
                    <div style={{overflowY: 'visible'}}>
                        <div className="chaptertag">COMBAT ARMS</div><br/>
                        <div className="flexbox">{buttonCreator(combatChapters)}</div>
                    </div>
                    <div style={{overflowY: 'visible'}}>
                        <div className="chaptertag">COMBAT (SERVICE) SUPPORT</div><br/>
                        <div className="flexbox">{buttonCreator(cssChapters)}</div>
                    </div>
                    <div style={{overflowY: 'visible'}}>
                        <div className="chaptertag">SPECIAL USE CASE</div><br/>
                        <div className="flexbox">{buttonCreator(miscChapters)}</div>
                    </div>
                </div>
                }
                {chapterSelected &&
                    <div style={{position: "relative"}}>
                    <div>
                        <p style={{fontSize: "24px", fontWeight: "500"}}>Make your Selection</p>
                        <p style={{fontSize: "16px"}}>Note some options are only available based on previous selections</p>
                    </div>
                    <div className="dropdown">
                        <Select
                            id={'weaponSelection'}
                            placeholder="Select a Weapon..."
                            isClearable
                            options={weaponOptions}
                            value={weaponValue}
                            onChange={(newValue) => {setWeaponValue(newValue), setCatValue(null), setEveValue([])}}
                            noOptionsMessage={() => null}
                            isValidNewOption={() => false}
                            blurInputOnSelect={true}
                            styles = {{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'gold' : 'grey',
                                  boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                  '&:hover': {
                                    borderColor: 'gold'
                                  }
                                }),
                                option: (styles, { isDisabled, isFocused, isSelected }) => {
                                    return {
                                      ...styles,
                                      backgroundColor: isDisabled
                                        ? undefined
                                        : isSelected
                                        ? '#bababa'
                                        : isFocused
                                        ? '#f5f5f5'
                                        : undefined,
                                      color: 'black',
                                      cursor: isDisabled ? 'not-allowed' : 'default',
                                
                                      ':active': {
                                        ...styles[':active'],
                                        backgroundColor: '#bababa'
                                      },
                                    };
                                  },
                              }}
                            />
                    </div>
                    <div className="dropdown">
                        <Select
                            id={'eventTypeSelection'}
                            placeholder="Select a Event Type..."
                            isClearable
                            options={eventsOptions}
                            value={eventsValue}
                            onChange={(newValue) => {setEventsValue(newValue), setCatValue(null), setEveValue([])}}
                            noOptionsMessage={() => null}
                            isValidNewOption={() => false}
                            blurInputOnSelect={true}
                            styles = {{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'gold' : 'grey',
                                  boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                  '&:hover': {
                                    borderColor: 'gold'
                                  }
                                }),
                                option: (styles, { isDisabled, isFocused, isSelected }) => {
                                    return {
                                      ...styles,
                                      backgroundColor: isDisabled
                                        ? undefined
                                        : isSelected
                                        ? '#bababa'
                                        : isFocused
                                        ? '#f5f5f5'
                                        : undefined,
                                      color: 'black',
                                      cursor: isDisabled ? 'not-allowed' : 'default',
                                
                                      ':active': {
                                        ...styles[':active'],
                                        backgroundColor: '#bababa'
                                      },
                                    };
                                  },
                              }}/>
                    </div>
                    <div className="dropdown">
                        <Select
                            id={'catSelection'}
                            placeholder="Select a Category..."
                            isClearable
                            options={catOptions}
                            value={catValue}
                            onChange={(newValue) => {setCatValue(newValue), setEveValue([])}}
                            noOptionsMessage={() => null}
                            isValidNewOption={() => false}
                            blurInputOnSelect={true}
                            styles = {{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'gold' : 'grey',
                                  boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                  '&:hover': {
                                    borderColor: 'gold'
                                  }
                                }),
                                option: (styles, { isDisabled, isFocused, isSelected }) => {
                                    return {
                                      ...styles,
                                      backgroundColor: isDisabled
                                        ? undefined
                                        : isSelected
                                        ? '#bababa'
                                        : isFocused
                                        ? '#f5f5f5'
                                        : undefined,
                                      color: 'black',
                                      cursor: isDisabled ? 'not-allowed' : 'default',
                                
                                      ':active': {
                                        ...styles[':active'],
                                        backgroundColor: '#bababa'
                                      },
                                    };
                                  },
                              }}/>
                    </div>
                    <div className="dropdown">
                        <Select 
                            id={'trainingSelection'}
                            components={{ Option }}
                            placeholder={givePlaceholder(isEventsMulti)}
                            isClearable
                            options={eveOptions}
                            value={eveValue}
                            onChange={(newValue) => {setEveValue(newValue)}}
                            noOptionsMessage={() => null}
                            isValidNewOption={() => false}
                            isDisabled = {Boolean(!catValue)}
                            isMulti
                            controlShouldRenderValue={!isEventsMulti}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            styles = {{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'gold' : 'grey',
                                  boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                  '&:hover': {
                                    borderColor: 'gold'
                                  }
                                }),
                                option: (styles, { isDisabled, isFocused, isSelected }) => {
                                    return {
                                      ...styles,
                                      backgroundColor: isDisabled
                                        ? undefined
                                        : isSelected
                                        ? '#bababa'
                                        : isFocused
                                        ? '#f5f5f5'
                                        : undefined,
                                      color: 'black',
                                      cursor: isDisabled ? 'not-allowed' : 'default',
                                
                                      ':active': {
                                        ...styles[':active'],
                                        backgroundColor: '#bababa'
                                      },
                                    };
                                  },
                                  placeholder: defaultStyles => {
                                    return {
                                      ...defaultStyles,
                                      color: isEventsMulti ? '#333333' : '#808080'
                                    };
                                }
                              }}
                              />
                    </div>
                    <div>Weapon Count</div>
                    <Iterator onChange={setweaponCount} currValue={weaponCount} />
                    <div>Iteration Count</div>
                    <Iterator onChange={setIterationCount} currValue={iterationCount} />
                    {!(eveValue.length) &&
                    <div className="calccontainer">
                        Results
                        <div className="resultcontainer">
                            <div className="resultinnercontainer" >
                                <TableContainer component={Paper} sx={{ zIndex: 3, backgroundColor: "#f5f5f5" }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: "bold"}}>QTY</TableCell>
                                                <TableCell sx={{fontWeight: "bold"}}>Ammunition</TableCell>
                                                <TableCell sx={{fontWeight: "bold"}}>DODIC</TableCell>
                                            </TableRow>
                                        </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{cursor: "default"}}>{"ㅤㅤ"}</TableCell>
                                                    <TableCell sx={{cursor: "default"}}>{"ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ"}</TableCell>
                                                    <TableCell sx={{cursor: "default"}}>{"ㅤㅤㅤ"}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        <div className="calcbtncontainer">
                            <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}}
                                onClick={() => {setEveValue([]); setweaponCount(1); setIterationCount(1)}}>ADD TO CART</Button>
                            <Button sx={{backgroundColor: !is10Added ? "#f5f5f5":"#bababa", color: !is10Added ? 'black':'grey',
                                "&:hover, &.Mui-focusVisible": !is10Added ? { backgroundColor: "#f5f5f5" } : { backgroundColor: "#bababa" }, 
                                height: '32px', borderRadius: "5px"}}>ADD 10%</Button>
                        </div>
                    </div>
                    }
                    {(eveValue.length > 0) &&
                    <div className="calccontainer">
                        Results
                        <div className="resultcontainer">
                            <div className="resultinnercontainer" >
                                <TableContainer component={Paper} sx={{ zIndex: 3, backgroundColor: "#f5f5f5" }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: "bold"}}>QTY</TableCell>
                                                <TableCell sx={{fontWeight: "bold"}}>Ammunition</TableCell>
                                                <TableCell sx={{fontWeight: "bold"}}>DODIC</TableCell>
                                            </TableRow>
                                        </TableHead>
                                            <TableBody>
                                                {calculate(is10Added)}
                                            </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        <div className="calcbtncontainer">
                            <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}}
                                onClick={() => {setEveValue([]); setweaponCount(1); setIterationCount(1)}}>ADD TO CART</Button>
                            <Button sx={{backgroundColor: !is10Added ? "#f5f5f5":"#bababa", color: !is10Added ? 'black':'grey',
                                "&:hover, &.Mui-focusVisible": !is10Added ? { backgroundColor: "#f5f5f5" } : { backgroundColor: "#bababa" }, 
                                height: '32px', borderRadius: "5px"}} onClick={() => {set10Added(!is10Added)}}>ADD 10%</Button>
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
    
    function Footer() {
        return(
            <div className="footer">
                <img src="logowithtext.png" className="footerimage"/>
            </div>
        )
    }

    function Description() {
        if(eveValue.length > 0)
        return(
            <div className="description">
                    <h4 style={{marginTop: "0px"}}>Steps</h4><br/>
                    <ul style={{textAlign: "left"}}>
                        <CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select a Weapon Type<br/><br/>
                        <CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select an Event Type<br/><br/>
                        <CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select a Category<br/><br/>
                        <CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select all Training Exercises<br/><br/><br/>
                        <li>Add Weapon Count</li><br/>
                        <li>Add Number of Iterations</li><br/>
                        <li>Click "Add to Cart"</li><br/>
                        <li>Repeat as Needed</li>
                    </ul>
                    <br/>
                    <a style={{position: "absolute", left: "7vw"}} href="https://www.dau.edu/" target="_blank">CAPULDI</a>
                    <button className="contactbtn" onClick={() => {setModalOpen(!isModalOpen)}}>Contact Us</button>
                </div>
        )
        else if(catValue)
        return(
            <div className="description">
                    <h4 style={{marginTop: "0px"}}>Steps</h4><br/>
                    <ul style={{textAlign: "left"}}>
                        <CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select a Weapon Type<br/><br/>
                        <CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select an Event Type<br/><br/>
                        <CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select a Category<br/><br/>
                        <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select all Training Exercises<br/><br/><br/>
                        <li>Add Weapon Count</li><br/>
                        <li>Add Number of Iterations</li><br/>
                        <li>Click "Add to Cart"</li><br/>
                        <li>Repeat as Needed</li>
                    </ul>
                    <br/>
                    <a style={{position: "absolute", left: "7vw"}} href="https://www.dau.edu/" target="_blank">CAPULDI</a>
                    <button className="contactbtn" onClick={() => {setModalOpen(!isModalOpen)}}>Contact Us</button>
                </div>
        )
        else if(eventsValue)
        return(
            <div className="description">
                    <h4 style={{marginTop: "0px"}}>Steps</h4><br/>
                    <ul style={{textAlign: "left"}}>
                        <CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select a Weapon Type<br/><br/>
                        <CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select an Event Type<br/><br/>
                        <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select a Category<br/><br/>
                        <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select all Training Exercises<br/><br/><br/>
                        <li>Add Weapon Count</li><br/>
                        <li>Add Number of Iterations</li><br/>
                        <li>Click "Add to Cart"</li><br/>
                        <li>Repeat as Needed</li>
                    </ul>
                    <br/>
                    <a style={{position: "absolute", left: "7vw"}} href="https://www.dau.edu/" target="_blank">CAPULDI</a>
                    <button className="contactbtn" onClick={() => {setModalOpen(!isModalOpen)}}>Contact Us</button>
                </div>
        )
        else if(weaponValue)
        return(
            <div className="description">
                    <h4 style={{marginTop: "0px"}}>Steps</h4><br/>
                    <ul style={{textAlign: "left"}}>
                        <CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select a Weapon Type<br/><br/>
                        <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select an Event Type<br/><br/>
                        <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select a Category<br/><br/>
                        <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select all Training Exercises<br/><br/><br/>
                        <li>Add Weapon Count</li><br/>
                        <li>Add Number of Iterations</li><br/>
                        <li>Click "Add to Cart"</li><br/>
                        <li>Repeat as Needed</li>
                    </ul>
                    <br/>
                    <a style={{position: "absolute", left: "7vw"}} href="https://www.dau.edu/" target="_blank">CAPULDI</a>
                    <button className="contactbtn" onClick={() => {setModalOpen(!isModalOpen)}}>Contact Us</button>
                </div>
        )
        else
        return(
            <div className="description">
                    <h4 style={{marginTop: "0px"}}>Steps</h4><br/>
                    <ul style={{textAlign: "left"}}>
                        <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select a Weapon Type<br/><br/>
                        <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select an Event Type<br/><br/>
                        <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select a Category<br/><br/>
                        <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select all Training Exercises<br/><br/><br/>
                        <li>Add Weapon Count</li><br/>
                        <li>Add Number of Iterations</li><br/>
                        <li>Click "Add to Cart"</li><br/>
                        <li>Repeat as Needed</li>
                    </ul>
                    <br/>
                    <a style={{position: "absolute", left: "7vw"}} href="https://www.dau.edu/" target="_blank">CAPULDI</a>
                    <button className="contactbtn" onClick={() => {setModalOpen(!isModalOpen)}}>Contact Us</button>
                </div>
        )
    }

    return(
            <div className="container">
            <>{Header()}</>
            <>{MainContent()}</>
                {!chapterSelected &&
                <div className="description">
                    <h4 style={{marginTop: "0px", }}>What is AmmoOp?</h4><br/>
                    <p style={{fontWeight: "bold"}}>*This Project is in a TESTING PHASE. Any issues or bugs found should be detailed in an 
                        email to the project manager found at the "contact us" link at the bottom of the webpage.*</p>
                    <p>AmmoOp is designed to simplify the process of ordering ammunition for individuals of any rank or position. It automatically 
                        calculates all ammunition requirements based on the latest STRAC for the chosen event or series of events. In upcoming updates, 
                        we plan to introduce a feature that allows users to create custom live fire ranges. For now, we hope you find value in the 
                        current version, saving you both time and reducing stress. Enjoy using AmmoOp!</p>
                    <br/>
                    <a style={{position: "absolute", left: "7vw"}} href="https://www.dau.edu/" target="_blank">CAPULDI</a>
                    <button className="contactbtn" onClick={() => {setModalOpen(!isModalOpen)}}>Contact Us</button>
                </div>
}
                {chapterSelected &&
                <>{Description()}</>
                }
                {chapterSelected &&
                <div className="quickbox">
                    <p>Quick Requistion Links</p>
                </div>
                }
            <>{Footer()}</>
            <Modal
                isOpen={isModalOpen}
                ariaHideApp={false}
                onRequestClose={() => {setModalOpen(false)}}
                style={MODAL_STYLE_FIT_CONTENT}
                contentLabel="PM Information">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>{STR_PROJECT_MANAGER_INFO}</div>
                    <a href={`mailto: ${STR_PROJECT_MANAGER_EMAIL}`}>{STR_PROJECT_MANAGER_EMAIL}</a>
                </div>
            </Modal>
            </div>
    )
}

export default AmmoOP;