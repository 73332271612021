import React from "react";
import { CATEGORY_TEST, ALL_CATEGORIES, ALL_CHAPTERS, COLOR, MODAL_STYLE_FIT_CONTENT, StyledAppButton, ARMY_DODICS, MAX_PAGES, ALL_CHAPTER_NAMES } from "./constants";
import { ICategory, IEvent, IStrategy } from "./interfaces";
import Modal from 'react-modal';
import styled from "styled-components";
import EventSelector from "./components/eventSelector";;
import Iterator from "./components/iterator";
import AppFooter from "./components/appfooter";
import { createFunctionalTextFields, createNonFunctionalFields, createSecondaryPagesTextFields } from "./components/pdfConstruction";;
import { PDFDocument, StandardFonts, PDFForm, PDFPage } from 'pdf-lib';
import fontKit from '@pdf-lib/fontkit';
import { Tooltip } from "@mui/material";

enum EModalState {
    CLOSED,
    SELECTING_CATEGORY,
    SELECTING_EVENTS,
    SELECTING_CHAPTERS
}

const StyledButton = styled.button`
    border: 2px solid ${COLOR.ARMY_GOLD};
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
`

export let testingTotals: IStrategy[] = [];
ALL_CATEGORIES.push(CATEGORY_TEST);

function App(): React.ReactElement {
    const [categories, ] = React.useState<ICategory[]>(ALL_CATEGORIES);
    const [category, setCategory] = React.useState<string>('SELECT CATEGORY');
    const [availableEvents, setAvailableEvents] = React.useState<IEvent[]>([]);
    const [calculatedTotals, setCalculatedTotals] = React.useState<string[]>([]);
    const [calculatedTotalsExportable, setCalculatedTotalsExportable] = React.useState<IStrategy[]>([]);
    const [calculatedTotalsSaved, setCalculatedTotalsSaved] = React.useState<IStrategy[]>([]);
    const [dodicOrder, setDodicOrder] = React.useState<number[]>([]);
    const [weaponCount, setweaponCount] = React.useState<number>(1);
    const [iterationCount, setIterationCount] = React.useState<number>(1);
    const [eventSelected, setEventSelected] = React.useState<boolean>(false);
    const [modalState, setModalState] = React.useState<EModalState>(EModalState.SELECTING_CHAPTERS);
    const [savedState, setSavedState] = React.useState<boolean>(false);
    const [totalsSavedState, setTotalsSavedState] = React.useState<boolean>(false);
    const [chapterState, setChapterState] = React.useState<string>('SELECT BRANCH');
    React.useEffect(() => {
        setCategory('SELECT CATEGORY');
        setAvailableEvents(resetSelectedValues([]));
        setIterationCount(1);
        setweaponCount(1);
    }, [chapterState])

    React.useEffect(() => {
        setIterationCount(1);
        setweaponCount(1);
    }, [category])

    React.useEffect(() => {
        isEventSelected();
        calculate();
    }, [availableEvents])

    React.useEffect(() => {
        calculate();
    }, [weaponCount, iterationCount])

    React.useEffect(() => {
        if (eventSelected) calculate();
    }, [eventSelected])

    React.useEffect(() => {
        if (!calculatedTotalsSaved.length){
            calculate();
            setTotalsSavedState(false);
        }
        else setTotalsSavedState(true);
    }, [calculatedTotalsSaved])

    React.useEffect(() => {
        testingTotals = calculatedTotalsExportable;
    }, [calculatedTotalsExportable])

    function calculate(): void {

        const sorted: IEvent[] = [];
        const calculations: string[] = [];
        const calculationsExportable: IStrategy[] = [];
        const dodicsOrder: number[] = [];
        const Dodics: { [charRep : string]: IStrategy[] } = {};
        setSavedState(false);

        for (let i = 0; i < availableEvents.length; i++) {
            if (availableEvents[i].isSelected)
                sorted.push(availableEvents[i])
        }
        
        for (let i = 0; i < calculatedTotalsSaved.length; i++){
            if(Dodics[calculatedTotalsSaved[i].charRep] === undefined){
                Dodics[calculatedTotalsSaved[i].charRep] = [];
            }
            Dodics[calculatedTotalsSaved[i].charRep].push(calculatedTotalsSaved[i]);
        }

        for (let i = 0; i < sorted.length; i++) {
            for (let j = 0; j < sorted[i].strategies.length; j++) {
                if (Dodics[sorted[i].strategies[j].charRep] === undefined){
                    Dodics[sorted[i].strategies[j].charRep] = [];
                    Dodics[sorted[i].strategies[j].charRep].push({charRep:sorted[i].strategies[j].charRep, count: 0 })
                }
                Dodics[sorted[i].strategies[j].charRep].push(sorted[i].strategies[j]);
            }
        }

        for (const key in Dodics){
            for (let i = 0; i < ARMY_DODICS.length; i++)
                if (Dodics[key][0].charRep === ARMY_DODICS[i].charRep){
                    let sum = 0;
                    for (let x = 1; x < Dodics[key].length; x++)
                        sum += Dodics[key][x].count;
                    if (sum || Dodics[key][0].count != 0){
                    calculations.push(`(${Math.ceil(((sum * weaponCount * iterationCount) + Dodics[key][0].count))}) ${ARMY_DODICS[i].ammoName}`)
                    calculationsExportable.push({charRep: Dodics[key][0].charRep, count: (Math.ceil((sum * weaponCount * iterationCount) + Dodics[key][0].count))})
                    dodicsOrder.push(i);
                    }
                }
        }

        setCalculatedTotals([...calculations]);
        setCalculatedTotalsExportable([...calculationsExportable]);
        setDodicOrder([...dodicsOrder]);
    }

    function renderCalculatedTotals(): React.ReactElement[] {
        const elements: React.ReactElement[] = [];
        for (let i = 0; i < calculatedTotals.length; i++) {
            elements.push(<div key={`calculation-${i}`}>{calculatedTotals[i]}</div>)
        }

        return elements;
    }

    function resetSelectedValues(values: IEvent[]): IEvent[] {
        for (let i = 0; i < values.length; i++) {
            values[i].isSelected = false;
        }

        return values;
    }

    function renderCategories(): React.ReactElement[] {
        const elements: React.ReactElement[] = [];

        for (let i = 0; i < categories.length; i++) {
             for (let x = 0; x < categories[i].chapters.length; x++){
                 if(categories[i].chapters[x] === chapterState){
                    elements.push(<StyledAppButton key={`category-${i}`} 
                    style={{ minHeight: '80px', height: '80px', width: '-webkit-fill-available' }}
                    onClick={() => {
                        setAvailableEvents(resetSelectedValues(categories[i].events));
                        setModalState(EModalState.CLOSED);
                        setCategory(categories[i].name);
                    }}
                    text={categories[i].name}
                />)
                 }}
        }

        if (elements.length === 0) {
            elements.push(<StyledAppButton key={`category-${0}`} 
                    style={{ minHeight: '80px', height: '80px', width: '-webkit-fill-available' }}
                    onClick={() => {
                        setModalState(EModalState.CLOSED);
                    }}
                    text={"SELECT A BRANCH FIRST"}
                />)
                 }

        return elements;
    }

    function renderChapters(): React.ReactElement[] {
        const elements: React.ReactElement[] = [];

        for (let i = 0; i < ALL_CHAPTERS.length; i++) {
            elements.push(<StyledAppButton key={`ALL_CHAPTERS-${i}`} 
                style={{ minHeight: '80px', height: '80px', width: '-webkit-fill-available' }}
                onClick={() => {
                    setModalState(EModalState.CLOSED);
                    setChapterState(ALL_CHAPTER_NAMES[i]);
                }}
                text={ALL_CHAPTER_NAMES[i]}
                />)
        }

        return elements;
    }

    function renderModal(): React.ReactElement {
        switch (modalState) {
            case EModalState.SELECTING_CATEGORY:
                return <div>{renderCategories()}</div>
            case EModalState.SELECTING_EVENTS:
                return <EventSelector events={availableEvents} onChange={(index: number, value: boolean) => {
                    if (index > -1 && index < availableEvents.length) {
                        const copy = availableEvents;
                        copy[index].isSelected = value;

                        setAvailableEvents([...copy]);
                    }
                }} 
                onExit={() => {setModalState(EModalState.CLOSED)}}
                />;
            case EModalState.SELECTING_CHAPTERS:
                return <div><div style={{ display: 'flex', borderRadius: '5px', alignItems: 'center', minHeight: '80px', cursor: 'default',
                    height: '80px', width: '-webkit-fill-available' }}>
                    <span style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden',
                        textOverflow: 'ellipsis' }}>SELECT BRANCH</span>
                    </div>
                    <div style ={{height: '3vh'}}></div>
                    {renderChapters()}</div>
            default:
                return <div>Oh Noers :(</div>
        }
    }

    function isEventSelected(): boolean {
        for (let i = 0; i < availableEvents.length; i++) {
            if (availableEvents[i].isSelected){
                setEventSelected(true);
                return true;
            }
        }
        setEventSelected(false);
        return false;
    }

    function fillTextFields(form: PDFForm, pages: PDFPage[]): void {
        for (let i = 0; i < calculatedTotalsExportable.length && i < (MAX_PAGES * 20 + 7); i++){
            if (calculatedTotalsExportable[i]){
                form.getTextField(('item.'+(i+1).toString())).setText(((i+1).toString()))
                form.getTextField(('dodic.'+(i+1).toString())).setText(calculatedTotalsExportable[i].charRep)
                form.getTextField(('nsn.'+(i+1).toString())).setText(ARMY_DODICS[dodicOrder[i]].nsn)
                form.getTextField(('name.'+(i+1).toString())).setText(ARMY_DODICS[dodicOrder[i]].nomenclature)
                form.getTextField(('amount.'+(i+1).toString())).setText((calculatedTotalsExportable[i].count.toString()))
            }
        }
        form.getTextField('5.page.left').setText('1')
        form.getTextField('5.page.right').setText(pages.length.toString())
        for (let i = 1; i < pages.length; i++){
            form.getTextField(('page.left.'+(i).toString())).setText((i+1).toString())
            form.getTextField(('page.right.'+(i).toString())).setText(pages.length.toString())
        }
    }
    
    async function modifyPdf() {
        const pdfData = await fetch('./DA581.pdf').then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(pdfData, { ignoreEncryption: true })
        if(calculatedTotalsExportable.length > 7){
            const pagesToGo = Math.ceil((calculatedTotalsExportable.length-7)/20);
            const pdfData2 = await fetch('./DA5811.pdf').then(res => res.arrayBuffer());
            const pdfDoc2 = await PDFDocument.load(pdfData2, { ignoreEncryption: true });
            for(let i = 0; i < pagesToGo && i < MAX_PAGES; i++) {
                const [pageTwo] = await pdfDoc.copyPages(pdfDoc2, [0])
                pdfDoc.insertPage((i+1), pageTwo);
            }

        }
        const form = pdfDoc.getForm()
        pdfDoc.registerFontkit(fontKit);
        const pages = pdfDoc.getPages()
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const cursiveFontData = await fetch('./Allura-Regular.ttf').then(res => res.arrayBuffer());
        const cursiveFont = await pdfDoc.embedFont(cursiveFontData);

        createNonFunctionalFields(form, helveticaFont, pages, cursiveFont);
        createFunctionalTextFields(form, helveticaFont, pages);
        if(calculatedTotalsExportable.length > 7) createSecondaryPagesTextFields(form, helveticaFont, pages, calculatedTotalsExportable.length);
        fillTextFields(form, pages);

        try{
        const pdfBytes = await pdfDoc.save({ });
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
        }
        catch{
            return 1;
        }
    }

    return (
    <div>
        <div style={{height: '100vh', width: '100vw', backgroundColor: 'black', position: 'absolute', zIndex: '-5'}} />
        <div style= {{ textAlign: 'center', display: 'flex', flexDirection: 'row', color: 'white', width: '100%', fontSize: '24px', top: '5px',
             borderBottom: `3px solid ${COLOR.ARMY_GOLD}`, justifyItems: 'center' }}>
                <div style= {{ position: 'absolute', left: '0px', right: '0px', cursor: 'default' }}>AmmoOp</div>
                <StyledButton style={{ right: '0px', borderWidth: '0', fontSize: '20px', backgroundColor: `${COLOR.ARMY_GOLD}`, borderRadius: '0px', maxWidth: '33vw',
                    width: 'fit-content', padding: '6px', height: '29px', marginLeft: 'auto', display: 'flex', position: 'relative', overflow: 'hidden' }}
                    onClick={() => {setModalState(EModalState.SELECTING_CHAPTERS)}}>{chapterState}</StyledButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', color: 'white', width: '60%', height: '100%', margin: 'auto' }}>
            <StyledButton style={{ marginTop: '20px' }} onClick={() => {setModalState(EModalState.SELECTING_CATEGORY)}}>{category}</StyledButton>
            {
                availableEvents.length > 0 && 
                <StyledButton style={{ marginTop: '20px' }} onClick={() => {setModalState(EModalState.SELECTING_EVENTS)}}>SELECT EVENTS</StyledButton>
            }
            {
                eventSelected &&
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px', fontSize: '24px', cursor: 'default' }}>Weapon Count</div>
                    <Iterator onChange={setweaponCount} currValue={weaponCount} />
                    <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px', fontSize: '24px', cursor: 'default' }}>Iterations Expected</div>
                    <Iterator onChange={setIterationCount} currValue={iterationCount} />
                    <div id={'calculationsBox'} style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px', fontSize: '24px', overflowY: 'auto',
                         scrollbarWidth: 'thin', scrollbarColor: `${COLOR.ARMY_GOLD}, black`, height: '30vh', border: '2px solid',
                         borderColor: savedState ? 'white' : 'transparent' }}>
                        {renderCalculatedTotals()}
                    </div>
                    <Tooltip title="Save for further calculations" disableInteractive = {true} placement = 'top' enterTouchDelay = {3000} enterDelay = {3000} >
                        <button id={'saveButton' }style={{ marginTop: '10vh', minHeight: '40px', backgroundColor: savedState ? 'black' : 'grey',
                         cursor: 'pointer' }} onClick={() => { 
                                setCalculatedTotalsSaved(calculatedTotalsExportable);
                                setSavedState(true);
                        }}>Save</button>
                    </Tooltip>
                    <Tooltip title="Clear saved calculations" disableInteractive = {true} placement = 'top' enterTouchDelay = {3000} enterDelay = {3000} >
                    <button id={'clearButton'} style={{ marginTop: '1vh', minHeight: '40px', backgroundColor: totalsSavedState ? 'grey' : 'black',
                     cursor: 'pointer' }} onClick={() => { 
                            setCalculatedTotalsSaved([]);
                        }}>Clear</button>
                    </Tooltip>
                    <Tooltip title="Save to DA-581" disableInteractive = {true} enterTouchDelay = {3000} enterDelay = {3000} >
                    <button style={{ marginTop: '1vh', minHeight: '40px', cursor: 'pointer' }} onClick={() => { 
                            modifyPdf(); 
                        }}>Export</button>
                    </Tooltip>
                </div>
            }
            <Modal
                isOpen={modalState !== EModalState.CLOSED}
                ariaHideApp={false}
                onRequestClose={() => {setModalState(EModalState.CLOSED)}}
                style={MODAL_STYLE_FIT_CONTENT}
                contentLabel="AmmoOp Modal"
            >
                {renderModal()}
            </Modal>
        </div>
        <AppFooter />
    </div>
    )
}

export default App;
