import React from "react";
import AmmoOP from "./ammoOP";

function Facelift(): React.ReactElement {
    const [ isTimedOut, setTimedOut ] = React.useState(false);

    function Timedout() {
        setTimedOut(true)
    }

    setTimeout(() => {
        Timedout();
        }, 1000);
    return(
        <div style={{overflow: "hidden", top: "0px"}}>
            {!isTimedOut &&
            <div style={{ width: '100%', height: '100%' }}>
                <img style={{ position: 'absolute', width: '100%', height: '100%' }} src="wholepage.jpg" alt="wholepage"/>
            </div>
            }
            {isTimedOut &&
            <AmmoOP />
            }
        </div>
    )
}

export default Facelift;