import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface IIteratorProps {
    onChange: (value: number) => void
    currValue: number
}

function Iterator(props: IIteratorProps): React.ReactElement {    
    return (
        <div style={{ display: 'flex', margin: 'auto', alignItems: 'center', fontSize: '16px', cursor: 'default' }}>
            <span className='iterator5' onClick={() => {
                        if (props.currValue > 5) props.onChange(props.currValue - 5)
                        if (props.currValue <= 5) props.onChange((props.currValue+1) - props.currValue)
                    }}>
                <RemoveIcon style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => {
                        if (props.currValue > 5) props.onChange(props.currValue - 5)
                        if (props.currValue <= 5) props.onChange((props.currValue+1) - props.currValue)
                    }}>
                </RemoveIcon>
            5</span>
            <span style={{marginRight: '10px'}}></span>
            <span className='iterator1' onClick={() => {
                        if (props.currValue > 1) props.onChange(props.currValue - 1)
                    }}>
                <RemoveIcon style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '1.5px' }} onClick={() => {
                        if (props.currValue > 1) props.onChange(props.currValue - 1)
                    }}>
                </RemoveIcon>
            </span>
            <span style={{marginRight: '4px', marginLeft: '12px', marginTop: '6px', alignContent: 'center'}}>
                {props.currValue}
            </span>
            <span style={{marginLeft: '10px'}}></span>
            <span className='iterator1' onClick={() => {props.onChange(props.currValue + 1)}}>
                <AddIcon style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '1.5px' }} onClick={() => {props.onChange(props.currValue + 1)}}></AddIcon>
            </span>
            <span style={{marginLeft: '10px'}}></span>
            <span className='iterator5' onClick={() => {props.onChange(props.currValue + 5)}}>
                <AddIcon style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => {props.onChange(props.currValue + 5)}}></AddIcon>
            5</span>
        </div>
    )
}

export default Iterator